import { useState, useEffect } from 'react';
import {
  Typography,
  CssBaseline,
  Button,
  Box,
  Textarea,
  Stack,
  Grid,
  Switch,
  Card,
  Select,
  Option,
  List,
  ListItem,
  ListItemContent,
  Alert
} from '@mui/joy';
import { init, say } from '../modules/speech';
import { translate } from '../modules/translate';
import speakerImage from '../images/speaker.jpg';
import { FileDownload } from '@mui/icons-material';

const App = () => {
  const voiceOptions = [
    {
      languageCode: 'en-US',
      name: 'en-US-Neural2-C',
      summary: 'American (female)'
    },
    {
      languageCode: 'en-GB',
      name: 'en-GB-Neural2-A',
      summary: 'British (female)'
    },
    {
      languageCode: 'ja-JP',
      name: 'ja-JP-Neural2-B',
      summary: 'Japanese (female)'
    },
    {
      languageCode: 'fr-FR',
      name: 'fr-FR-Neural2-D',
      summary: 'French (male)'
    },
    {
      languageCode: 'it-IT',
      name: 'it-IT-Neural2-A',
      summary: 'Italian (female)'
    }
  ];

  const [text, setText] = useState('');
  const [audioController, setAudioController] = useState(null);
  const [audioState, setAudioState] = useState('idle');
  const [translateText, setTranslateText] = useState(false);
  const [speakingRate, setSpeakingRate] = useState(1);
  const [speakingVoice, setSpeakingVoice] = useState(
    parseInt(localStorage.getItem('voice')) || 0
  );
  const [audioData, setAudioData] = useState(null);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    localStorage.setItem('voice', speakingVoice);
  }, [speakingVoice]);

  const initializeAudio = () => {
    if (!initialized) {
      init();
      setInitialized(true);
    }
  };

  const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = `data:audio/wav;base64,${audioData}`;
    link.download = 'speech.wav';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sayText = async () => {
    initializeAudio();
    setAudioState('loading');
    setError(null);

    try {
      let textToSpeak = text;
      if (translateText) {
        textToSpeak = await translate(text);
      }

      setAudioData(null);

      const controller = await say({
        text: textToSpeak,
        onStateChange: newState => {
          setAudioState(newState);
          if (newState === 'finished' || newState === 'idle') {
            setAudioController(null);
            setAudioData(null);
          }
        },
        speakingRate,
        speakingVoice: {
          name: voiceOptions[speakingVoice].name,
          languageCode: voiceOptions[speakingVoice].languageCode
        }
      });

      setAudioController(controller);
    } catch (err) {
      console.error('Error in text-to-speech:', err);
      setError(`Error: ${err.message || 'An unknown error occurred'}`);
      setAudioState('idle');
      setAudioController(null);
      setAudioData(null);
    }
  };

  const handlePlayPause = () => {
    if (audioController) {
      if (audioState === 'playing') {
        audioController.pause();
      } else {
        audioController.resume();
      }
    }
  };

  const handleCancel = () => {
    if (audioController) {
      audioController.stop();
    }
    setAudioController(null);
    setAudioState('idle');
    setAudioData(null);
  };

  const isAudioActive = audioState !== 'idle' && audioState !== 'finished';

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${speakerImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          py: 3
        }}
      >
        <Grid container justifyContent="center">
          <Grid xs={12} md={6}>
            <Stack spacing={3}>
              <Typography level="h2" textColor="white" textAlign="center">
                Speech AI
              </Typography>
              {error && (
                <Alert color="danger" variant="soft">
                  {error}
                </Alert>
              )}
              <Stack alignItems="center">
                <Card
                  sx={theme => ({
                    backgroundColor: theme.palette.primary.solidBg
                  })}
                >
                  <List sx={{ minWidth: 290 }}>
                    <ListItem>
                      <ListItemContent
                        sx={theme => ({
                          color: theme.palette.common.white
                        })}
                      >
                        Translate to English
                      </ListItemContent>
                      <Switch
                        checked={translateText}
                        disabled
                        onChange={event => {
                          const activated = event.target.checked;
                          setTranslateText(activated);
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemContent
                        sx={theme => ({
                          color: theme.palette.common.white
                        })}
                      >
                        Speaking speed
                      </ListItemContent>
                      <Select
                        onChange={(evt, val) => setSpeakingRate(val)}
                        disabled={isAudioActive}
                        value={speakingRate}
                      >
                        {[0.888, 1, 1.11, 1.55].map(rate => (
                          <Option value={rate} key={rate}>
                            {rate}x
                          </Option>
                        ))}
                      </Select>
                    </ListItem>
                    <ListItem>
                      <ListItemContent
                        sx={theme => ({
                          color: theme.palette.common.white
                        })}
                      >
                        Voice
                      </ListItemContent>
                      <Select
                        onChange={(evt, val) => {
                          setSpeakingVoice(val);
                        }}
                        disabled={isAudioActive}
                        value={speakingVoice}
                      >
                        {voiceOptions.map((voice, i) => (
                          <Option value={i} key={i}>
                            {voiceOptions[i].summary}
                          </Option>
                        ))}
                      </Select>
                    </ListItem>
                    {audioData && (
                      <ListItem>
                        <ListItemContent
                          sx={theme => ({
                            color: theme.palette.common.white
                          })}
                        >
                          Want a copy of the audio?
                        </ListItemContent>
                        <Button
                          variant="soft"
                          onClick={downloadAudio}
                          startDecorator={<FileDownload />}
                        >
                          Download
                        </Button>
                      </ListItem>
                    )}
                  </List>
                </Card>
              </Stack>
              <Box p={2} alignSelf="stretch">
                <Textarea
                  autoFocus
                  disabled={isAudioActive}
                  onChange={event => setText(event.target.value)}
                  value={text}
                  minRows={11}
                  maxRows={11}
                  sx={{ overflow: 'auto' }}
                  placeholder="What would you like me to say?"
                />
              </Box>
              <Stack>
                {!isAudioActive ? (
                  <Stack
                    direction="row"
                    spacing={8}
                    justifyContent="space-around"
                  >
                    <Button
                      variant="soft"
                      size="lg"
                      onClick={() => {
                        setText('');
                        setAudioData(null);
                      }}
                      disabled={text === ''}
                    >
                      Clear text
                    </Button>
                    <Button
                      variant="solid"
                      size="lg"
                      onClick={sayText}
                      disabled={text === ''}
                    >
                      Read it to me
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" justifyContent="space-around">
                    <Button
                      variant="soft"
                      color="danger"
                      size="lg"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="soft"
                      size="lg"
                      color="primary"
                      onClick={handlePlayPause}
                      disabled={audioState === 'loading'}
                    >
                      {audioState === 'playing'
                        ? 'Pause playback'
                        : 'Resume Playback'}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default App;
