import sound from '../sounds/cork.json';

const speech = new Audio();
speech.autoplay = true;

const init = () => (speech.src = `data:audio/wav;base64,${sound.base64}`);

const MAX_CHARS = 4900; // Slightly less than 5000 to be safe

const splitText = text => {
  const chunks = [];
  let start = 0;
  while (start < text.length) {
    let end = start + MAX_CHARS;
    if (end < text.length) {
      // Find the last space before the limit
      end = text.lastIndexOf(' ', end);
      if (end === -1 || end <= start) {
        end = start + MAX_CHARS;
      }
    }
    chunks.push(text.slice(start, end));
    start = end;
  }
  return chunks;
};

const say = async ({ text, onStateChange, speakingRate, speakingVoice }) => {
  const chunks = splitText(text);
  let audioChunks = [];

  for (let i = 0; i < chunks.length; i++) {
    try {
      const response = await fetch(
        `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            audioConfig: {
              audioEncoding: 'LINEAR16',
              speakingRate
            },
            input: {
              text: chunks[i]
            },
            voice: speakingVoice
          })
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      audioChunks.push(`data:audio/wav;base64,${data.audioContent}`);
    } catch (error) {
      console.error(`Error synthesizing chunk ${i}:`, error);
      throw error;
    }
  }

  let currentChunk = 0;
  const audio = new Audio();
  let isStopped = false;

  const playNextChunk = () => {
    if (isStopped) return;
    if (currentChunk < audioChunks.length) {
      audio.src = audioChunks[currentChunk];
      audio
        .play()
        .then(() => {
          onStateChange('playing');
        })
        .catch(error => {
          console.error('Error playing audio:', error);
          onStateChange('error');
        });
    } else {
      onStateChange('finished');
    }
  };

  audio.addEventListener('ended', () => {
    currentChunk++;
    playNextChunk();
  });

  audio.addEventListener('pause', () => {
    if (!isStopped) onStateChange('paused');
  });

  audio.addEventListener('play', () => {
    onStateChange('playing');
  });

  playNextChunk();

  return {
    pause: () => {
      audio.pause();
    },
    resume: () => {
      if (!isStopped) audio.play();
    },
    stop: () => {
      isStopped = true;
      audio.pause();
      audio.currentTime = 0;
      currentChunk = audioChunks.length; // Prevent further playback
      onStateChange('idle');
    }
  };
};

export { say, init };
